<template>
<div>
    <document-ref-list :items="items"/>
</div>
</template>
<script>
export default {
  components: {
    DocumentRefList: () => import('@/components/document-ref-list')
  },
  data () {
    return {
      items: [
        // {
        //   id: '7A80BB95-B01E-4593-BDE9-014015AB7F62',
        //   type: 'account-contacts'
        // },
        {
          id: '3DA45759-517E-D727-23DA-08C6307A66CC',
          type: 'users'
        },
        // {
        //   id: '313DBFF7-D208-48CE-8E56-0185755C23BC',
        //   type: 'account-contacts'
        // },
        // {
        //   id: '6C60985F-FD58-4DD4-BF23-022EBCD71FF7',
        //   type: 'account-contacts'
        // },
        {
          id: '5759F50C-F0A7-4299-B0CA-03EEC3EEC350',
          type: 'account-contacts'
        },
        {
          id: '4AFD0673-ED74-4C9B-AA24-05A2D17D4DC3',
          type: 'account-contacts'
        },
        {
          id: '28A8931E-3B31-48B3-9D7A-646430063595',
          type: 'users'
        },
        {
          id: 'D55F087A-0260-97A2-4E6E-FBFF55C4E721',
          type: 'users'
        },
        {
          id: 'D55F087A-0260-97A2-4E6E-FBFF55C4E721',
          type: 'users'
        },
        {
          id: '7B673CCE-5D13-4601-CE28-52EE974E8F02',
          type: 'users'
        },
        // {
        //   id: '8FA4E246-95A0-E811-9CD7-DC5360F6357A',
        //   type: 'accounts'
        // },
        {
          id: '93A4E246-95A0-E811-9CD7-DC5360F6357A',
          type: 'invoices'
        }
      ]
    }
  }
}
</script>
